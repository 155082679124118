import React from "react";

import BackgroundedScreen from './BackgroundedScreen';
import { Button } from 'reactstrap';

export default (props) => {
  return (
    <BackgroundedScreen background={props.background} >
        <div style={{ textAlign: 'center' }}>
          <h3>Trop tard!</h3>
          <div style={{padding: 20}}>{props.error ? props.error : "error"}</div>
          <div className={"text-center"}>{props.retryCallback ? <Button onClick={props.retryCallback} className={"btn-raised"} color={"danger"}>Retry</Button> : null }</div>
        </div>
    </BackgroundedScreen>
  )
}
