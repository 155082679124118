module.exports = {
  environment: process.env.ENV,
  sentryEnvironment: process.env.SENTRY_ENVIRONMENT,
  defaultLocale: 'fr',
  release: process.env.RELEASE,
  sentryDsn: process.env.SENTRY_DSN,
  sentryName: process.env.SENTRY_NAME,
  mailer: {
    host: process.env.SMTP_HOST
  },
  database: {
    uri: `${process.env.MONGO_URI}${process.env.ENV === 'prod' ? '?replicaSet=rs0' : ''}`,
    upmyshopUri: `${process.env.MONGO_URI}/positive_customer${process.env.ENV === 'prod' ? '?replicaSet=rs0' : ''}`,
    sessionUri: `${process.env.MONGO_URI}/session_db${process.env.ENV === 'prod' ? '?replicaSet=rs0' : ''}`,
    surveyUri: `${process.env.MONGO_URI}/survey${process.env.ENV === 'prod' ? '?replicaSet=rs0' : ''}`,
  },
  adminLoginUrl: `https://${process.env.REACT_APP_UPMYSHOP_HOST}/admin`,
  adminApi: {
    url: `https://${process.env.REACT_APP_SURVEYSERVER_HOST}`
  },
  surveyHost: process.env.SURVEY_HOST,
  cookieDomain: process.env.COOKIE_DOMAIN,
  kafka: {
    sessionTimeout: 300,
    spinDelay: 100,
    retries: 2,
    topic: `${process.env.KAFKA_TOPIC_PREFIX}v2_survey`,
    dbEventTopic: `${process.env.KAFKA_TOPIC_PREFIX}v2_dbevent`,
    brokers: process.env.KAFKA_HOST ? process.env.KAFKA_HOST.split(',') : ''
  }
};
