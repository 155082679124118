import React, { Component } from 'react';
import { Alert, FormGroup, Input, Button, Form } from 'reactstrap';

import api from '../admin/services/Api';
import Loader from '../components/Loader';
import BackgroundedScreen from '../components/BackgroundedScreen';

export default class Login extends Component {

  state = {
    loading: true,
    loaded: false,
    customFields: null,
    connectionError: false,
    login: {
      email: '',
      password: ''
    },
  };

  componentDidMount() {
    console.log(this.props.connectionError);
    this.setState({
      loading: false,
      customFields: this.props.customFields,
      connectionError: this.props.connectionError ? this.props.connectionError : null
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.customFields) {
      this.setState({
        customFields: nextProps.customFields,
        loading: false,
        loaded: true
      });
    }
  }

  login = async () => {
    this.setState({
      loading: true
    });
    api.loginUser(this.state.login)
      .then((user) => {
        this.setState({
          loading: false
        });
        this.props.onLogin(user);
      })
      .catch((e, err) => {
        this.setState({
          connectionError: e.error.message,
          loading: false
        });
      })
    ;
  };

  handleConnectionChange = (e) => {
    this.setState({
      login: {
        email: e.target.name === 'email' ? e.target.value : this.state.login.email,
        password: e.target.name === 'password' ? e.target.value : this.state.login.password
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader
        backgroundImage={this.state.customFields ? this.state.customFields.backgroundImage : null}/>;
    }
    return (
      <BackgroundedScreen
        backgroundImage={this.state.customFields.backgroundImage ? this.state.customFields.backgroundImage : null}
        title={(
          <div className={'text-center'}>
            {this.state.customFields.loginLogo ?
              <div className={'loginLogo'} style={{ padding: 20 }}><img alt="Logo"
                src={this.state.customFields.loginLogo} style={{ maxWidth: '100%' }}/></div> : null}
            {this.state.customFields.loginTitle ? <div className={'loginTitle'}
                                                       style={{ padding: 20 }}>{this.state.customFields.loginTitle}</div> : null}
            {this.state.customFields.loginText ?
              <div className={'loginText text-secondary font-weight-light'}
                   style={{ padding: 20 }}>{this.state.customFields.loginText.split('\n')
                .map((item, key) => {
                  return <span key={key}>{item}<br/></span>;
                })}</div> : null}
          </div>
        )}
      >
        <Form>
          {this.state.connectionError ? (
            <Alert
              color={'danger'}>{typeof this.state.connectionError === 'string' ? this.state.connectionError : typeof this.state.connectionError.message != 'undefined' ? this.state.connectionError.message : typeof this.state.connectionError}</Alert>
          ) : null}
          <FormGroup>
            <Input className={'form-control form-control-lg'} type={'email'} placeholder={'Email'}
                   name={'email'} value={this.state.login.email}
                   onChange={this.handleConnectionChange}/>
          </FormGroup>
          <FormGroup>
            <Input className={'form-control form-control-lg'} type={'password'}
                   placeholder={'Password'} name={'password'} value={this.state.login.password}
                   onChange={this.handleConnectionChange}/>
          </FormGroup>
          <FormGroup className={'text-center'}>
            <Button className={'btn-raised'} color={'danger'} size={'lg'}
                    onClick={this.login}>{this.state.customFields.loginButton ? this.state.customFields.loginButton : 'Sign in'}</Button>
          </FormGroup>
        </Form>
      </BackgroundedScreen>
    );
  }
}
