import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import './index.css';
import {App} from './App';
import {SurveyCreatorWidget} from './SurveyCreatorWidget';
import SurveyResult from './SurveyResult';
import SurveyThankYou from './SurveyThankYou';


ReactDOM.render((
  <DocumentTitle title='UpMyShop! Surveys'>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => ""} />
        <Route exact path="/ping" render={() => "OK"} />
        <Route path="/survey-editor/:surveyid" component={SurveyCreatorWidget} />
        <Route path="/survey/result/:postid" component={SurveyResult} />
        <Route path="/survey/thankyou/:postid" component={SurveyThankYou} />
        <Route path="/:surveyslug" component={App} />
      </Switch>
    </BrowserRouter>
  </DocumentTitle>
), document.getElementById('root'));
