import React from "react";
import BackgroundedScreen from './BackgroundedScreen';

export default (props) => {
  return (
    <BackgroundedScreen backgroundImage={props.backgroundImage} >
        <div className={"text-center"}>
          <div className={"lds-ripple"}>
            <div></div><div></div>
          </div>
          <div>{props.text ? props.text : "Loading..."}</div>
        </div>
    </BackgroundedScreen>
  )
}
