import _ from 'lodash';

class QuizRules {

  getSurveyQuestion = (survey, questionId) => {
    for (let p in survey.json.pages) {
      for (let q in survey.json.pages[p].elements) {
        if (survey.json.pages[p].elements[q].name === questionId) {
          return survey.json.pages[p].elements[q];
        }
      }
    }
    return null;
  };

  getCategories = (survey, ignoreQuestionsOnPage=false, ignoreCorrectAnswer=false) => {
    const points = survey.customFields.points;
    const nbQuestions = this.getQuizNbOfQuestions(survey);
    const scoreByQuestion = Math.round(points / nbQuestions);
    const categories = {};
    let pNo = 0;
    for(let pageId in survey.json.pages) {
      if (pNo === 0 && survey.json.firstPageIsStarted) {
        pNo++;
        continue;
      }
      if ((!survey.json.pages[pageId].elements || !survey.json.pages[pageId].elements.length) && !ignoreQuestionsOnPage) {
        const errorMessage = {
          message: `Page ${parseInt(pageId)+1} has no question`
        }
        throw errorMessage;
      }
      for(let questionId in survey.json.pages[pageId].elements) {
        const question = survey.json.pages[pageId].elements[questionId];
        if (typeof question.category === 'undefined' && survey.customFields.categoryRequired) {
          const errorMessage = {
            message: `Question ${parseInt(questionId)+1} on page ${parseInt(pageId)+1} have a question without category`
          }
          throw errorMessage;
        }
        if (typeof question.correctAnswer === 'undefined' && !ignoreCorrectAnswer) {
          const errorMessage = {
            message: `Question ${parseInt(questionId)+1} on page ${parseInt(pageId)+1} has no correctAnswer set`
          }
          throw errorMessage;
        }
        categories[question.category] = {
          score: (typeof categories[question.category] !== 'undefined' ? categories[question.category].score : 0) + scoreByQuestion
        };
      }
      pNo++;
    }
    return categories;
  };

  getQuizNbOfQuestions = (survey) => {
    let nbQuestions = 0;
    const firstPageIsStart = survey.json.firstPageIsStarted;
    survey.json.pages.map((el, i) => {
      if ((i > 0 || !firstPageIsStart) && el.elements) {
        nbQuestions += el.elements.length;
      }
    });
    return nbQuestions;
  };

  getQuizScore = (post) => {
    const {survey, answers} = post;
    const points = parseInt(post.survey.customFields.points);
    const categories = this.getCategories(survey, true);
    const nbQuestions = this.getQuizNbOfQuestions(post.survey);

    if (!nbQuestions) {
      const errorMessage = {
        message: 'No question in quiz'
      };
      throw errorMessage;
    };

    // const scoreByQuestion = Math.round(points / nbQuestions);
    const scoreByQuestion = points / nbQuestions;

    let total= 0;
    let scoreByCategory = {};
    for (let questionId in answers) {
      const question = this.getSurveyQuestion(survey, questionId);

      if (!question) {
        const errorMessage = {
          message: 'one of the answers has no question in survey'
        };
        throw errorMessage;
      }
      let score = 0;
      if (Array.isArray(question.correctAnswer) && ['sortablelist', 'matrix'].indexOf(question.type) === -1) {
        let difference = question.correctAnswer
          .filter(x => !answers[questionId].includes(x))
          .concat(answers[questionId].filter(x => !question.correctAnswer.includes(x)));
        score = scoreByQuestion * ((question.choices.length - difference.length) / question.choices.length);
      } else {
        score = (typeof question.correctAnswer === 'string' && question.correctAnswer.toLowerCase() === answers[questionId].toLowerCase() ? 1 : _.isEqual(question.correctAnswer, answers[questionId]) ? 1 : 0) * scoreByQuestion;
      }
      
      total+= score;
      if (typeof question.category != 'undefined' && question.category !== 'none' && typeof categories[question.category] !== 'undefined') {
        scoreByCategory[question.category] = {
          score: (typeof scoreByCategory[question.category] !== 'undefined' ? scoreByCategory[question.category].score : 0) + score
        };
        scoreByCategory[question.category]['percent'] = Math.round(scoreByCategory[question.category].score / categories[question.category].score * 100);
      }
    }
    let level = null;
    for(let l in post.survey.customFields.levels) {
      if (total >= post.survey.customFields.levels[l].start && total < post.survey.customFields.levels[l].end) {
        level = post.survey.customFields.levels[l];
        break;
      }
    }
    return {
      total: Math.round(total),
      totalPercent: Math.round(total/points * 100),
      level,
      categories: scoreByCategory
    };
  };

  getQuizResultRadarData = (post, shortNames = 0) => {
    const radarData = [];
    const radarLabels = [...post.survey.customFields.questionCategories];

    if (shortNames) {
      for (const cName in radarLabels) {
        radarLabels[cName] = radarLabels[cName].split(' ').map((el) => { return el.length > shortNames ? el.substring(0, shortNames) + '.' : el}).join(' ');
      }
    }

    for (const category of post.survey.customFields.questionCategories) {
      if (typeof post.score.categories[category] != 'undefined') {
        radarData.push(post.score.categories[category].percent);
      } else {
        radarData.push(0);
      }
    }

    const radarDatasets = [{
      data: radarData,
      label: post.survey.name,
      fill: true,
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgb(255, 99, 132)',
      pointBackgroundColor: 'rgb(255, 99, 132)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(255, 99, 132)'
    }];

    if (post.survey.customFields.compareToSurvey && post.oldScores && post.oldScores.length) {
      const oldData = [];

      for (const category of post.survey.customFields.questionCategories) {
        if (typeof post.oldScores[0].categories[category] != 'undefined') {
          oldData.push(post.oldScores[0].categories[category].percent);
        } else {
          oldData.push(0);
        }
      }

      radarDatasets.push({
        data: oldData,
        label: post.oldScores[0].surveyName,
        fill: true,
        backgroundColor: 'rgba(90,90,90,0.2)',
        borderColor: 'rgb(85,85,85)',
        pointBackgroundColor: 'rgb(162,162,168)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(163,161,162)'
      });
    }

    return {
      radarDatasets,
      radarLabels
    };
  }
}

export default new QuizRules();

