import React, {Component} from "react";
import { Spinner, Collapse, Card, CardBody, FormGroup, Label, Input, FormText, TabContent, TabPane, Nav, NavItem, Col, Row, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {ChromePicker} from 'react-color';
import CreatableSelect from 'react-select/lib/Creatable';

import api from '../admin/services/Api';
import { Button } from "reactstrap";

let customCssFiles = [
  'mary-cohr.css',
];

const objectDataVenueFieldMappings = [
  'id',
  'externalId'
];

export default class CustomizeSurveyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.isOpen || false,
      styleFields: {
        ...this.styleFields,
        ...this.props.styleFields
      },
      otherFields: {
        ...this.otherFields,
        ...this.props.otherFields
      },
      brands: null,
      activeTab: '1',
      loginLogo: this.props.otherFields.loginLogo,
      backgroundImage: this.props.otherFields.backgroundImage,
      headerImage: this.props.otherFields.headerImage,
      levelImage: this.props.otherFields.levelImage,
      pictures: this.props.otherFields.pictures,
      loginLogoLoading: false,
      headerImageLoading: false,
      levelImageLoading: false,
      backgroundImageLoading: false,
      picturesLoading: false
    };
  }

  componentDidMount() {
    api.getBrands().then((brands) => {
      this.setState({brands});
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      collapse: typeof nextProps.isOpen != 'undefined' ? nextProps.isOpen : this.state.collapse,
      styleFields: typeof nextProps.styleFields != 'undefined' ? {
        ...this.state.styleFields,
        ...nextProps.styleFields
      } : this.state.styleFields,
      otherFields: typeof nextProps.otherFields != 'undefined' ? {
        ...this.state.otherFields,
        ...nextProps.otherFields,
      } : this.state.otherFields,
    });
  }

  getStyleFields = () => {
    return Object.keys(this.state.styleFields).map((field) => {
      return (
        <FormGroup key={field} className="col-md-4">
          <Label for={field}>{field}</Label>
          <div className="input-group input-group-sm mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text"><span
                style={{backgroundColor: this.state.styleFields[field], width: 20}}>&nbsp;</span></div>
            </div>
            <Input bsSize={"sm"} onFocus={this.onColorFocus} data-tree="style" type={field} name={field} id={field}
                   placeholder={field} onChange={this.onChange} value={this.state.styleFields[field]}
            />
          </div>
          {this.state.pickerVisible && this.state.editingColor === field ? this.renderPicker() : null}
        </FormGroup>
      );
    });
  }

  renderPicker = () => {
    return (
      <div style={{ position: 'absolute', zIndex: 10 }}>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }}
          onClick={() => {this.setState({editingColor: null, pickerVisible: false})}}
        ></div>
        <ChromePicker
          color={this.state.styleFields[this.state.editingColor]}
          onChangeComplete={this.handleColorChange}
          style={{ position: 'absolute' }}
        />
      </div>
      );
  }

  onColorFocus = (event) => {
    this.setState({
      editingColor: event.target.name,
      pickerVisible: true
    });
  }

  getBrandChoice = () => {
    if (!this.state.brands) {
      return <Spinner size="sm"/>;
    } else {
      return (
        <Input type="select" name="brand" id="brand" value={this.state.otherFields.brand} onChange={this.onChange}>
          <option key="empty">Select one to attach survey to a brand</option>
          {this.state.brands.map((brand) => (
            <option value={brand._id} key={brand._id}>{brand.name}</option>
          ))
          }
        </Input>
      );
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleUploadImage = async event => {
    if (event.target.name && event.target.files && event.target.files[0]) {
      let name = event.target.name;
      let url = '';
      let nameLoading = name +'Loading';
      this.setState({ [nameLoading]: true });
      if(name === "pictures") {
        let pictures = event.target.files;
        let urls = [];
        if(this.state.pictures !== undefined) {
          urls = [...this.state.pictures]
        }
        for(const img of Object.entries(pictures)) {
          url = await api.uploadAdminImage(this.props.survey._id, img[1]);
          if(url !== '') {
            urls.push(url);
            this.setState({
              [name]: urls,
              [nameLoading]: false
            });
            this.props.onChange(name, urls);
          }
        }
      } else {
        let img = event.target.files[0];
        this.props.onChange(name, '');
        url = await api.uploadAdminImage(this.props.survey._id, img);
        if(url !== '') {
          this.setState({
            [name]: url,
            [nameLoading]: false
          });
          this.props.onChange(name, url);
        }
      }
    }
  };

  deleteUploadImage(name) {
    this.props.onChange(name, '');
    this.setState({
      [name]: '',
    });
  };
  deleteUploadImageByIndex(name, index, file) {
    let newPictures = [...this.state.pictures];
    newPictures.splice(index, 1);
    this.props.onChange(name, newPictures);
    this.setState({
      [name]: newPictures,
    });
  };

  render = () => {
    return (
      <Collapse isOpen={this.state.collapse}>
        <Card className="custumizeSurveyCard">
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: this.state.activeTab === '1'})}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Config
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: this.state.activeTab === '2'})}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Styles
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: this.state.activeTab === '3'})}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Template
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: this.state.activeTab === '4'})}
                  onClick={() => {
                    this.toggle('4');
                  }}
                >
                  Errors
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({active: this.state.activeTab === '5'})}
                  onClick={() => {
                    this.toggle('5');
                  }}
                >
                  Pictures
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1" style={{paddingTop: 20}}>
                <Row>
                  <Col sm="12">
                    <div className="row">
                      <Col sm={"4"}>
                        <FormGroup key="brand">
                          <Label for="brand">Brand</Label>
                          {this.getBrandChoice()}
                        </FormGroup>
                        <FormGroup key="reportName">
                          <Label for="reportName">Report name</Label>
                          <Input type="text" name="reportName" id="reportName" value={this.state.otherFields.reportName} onChange={this.onChange} />
                        </FormGroup>
                        <div className="custom-control custom-checkbox" key="enabled">
                          <Input className="custom-control-input" type="checkbox" name="enabled" id="enabled" value="1" onChange={this.onChange} checked={this.state.otherFields.enabled}/>
                          <Label className="custom-control-label" check for="enabled">
                            Enabled
                          </Label>
                        </div>

                        <FormGroup key="objectDataVenueField">
                          <Label for="objectDataVenueField">Venue mapping field</Label>
                          <Input type="text" name="objectDataVenueField" id="objectDataVenueField" value={this.state.otherFields.objectDataVenueField} onChange={this.onChange} />
                        </FormGroup>
                        <FormGroup key="objectDataVenueFieldMapping">
                          <Label for="objectDataVenueFieldMapping">Venue field to map</Label>
                          <Input type="select" name="objectDataVenueFieldMapping" id="objectDataVenueFieldMapping" value={this.state.otherFields.objectDataVenueFieldMapping} onChange={this.onChange}>
                            <option key="empty" value={''}>Select</option>
                            {objectDataVenueFieldMappings.map((objectDataVenueFieldMapping) => (
                              <option value={objectDataVenueFieldMapping} key={objectDataVenueFieldMapping}>{objectDataVenueFieldMapping}</option>
                            ))
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <div className="custom-control custom-checkbox" key="quiz">
                          <Input className="custom-control-input" type="checkbox" name="quiz" id="quiz" value="1" onChange={this.onChange} checked={this.state.otherFields.quiz}/>
                          <Label className="custom-control-label" check for="quiz">
                            Quiz
                          </Label>
                          <FormText color={"muted"}>
                            This will be a quiz with results, please don't forget to set all other fields
                          </FormText>
                        </div>
                        <div className="custom-control custom-checkbox mt-2" key="random">
                          <Input className="custom-control-input" type="checkbox" name="random" id="random" value="1" onChange={this.onChange} checked={this.state.otherFields.random}/>
                          <Label className="custom-control-label" check for="random">
                            Random questions
                          </Label>
                          <FormText color={"muted"}>
                            Don't forget to set <b>Survey Settings > Question > Elements order on the page</b> to <b>random</b>
                          </FormText>
                        </div>
                        <div className="custom-control custom-checkbox mt-2" key="onceperuser">
                          <Input className="custom-control-input" type="checkbox" name="onceperuser" id="onceperuser" value="1" onChange={this.onChange} checked={this.state.otherFields.onceperuser}/>
                          <Label className="custom-control-label" check for="onceperuser">
                            Once per user
                          </Label>
                          <FormText color={"muted"}>
                            Each user can do it only once (session or user based if <b>secured</b> is checked)
                          </FormText>
                        </div>
                        <div className="custom-control custom-checkbox mt-2" key="secured">
                          <Input className="custom-control-input" type="checkbox" name="secured" id="secured" value="1" onChange={this.onChange} checked={this.state.otherFields.secured}/>
                          <Label className="custom-control-label" check for="secured">
                            Secured only
                          </Label>
                          <FormText color={"muted"}>
                            Only users having an account can participate
                          </FormText>
                        </div>
                        <div className="custom-control custom-checkbox mt-2" key="doNotRedirectToCompletePage">
                          <Input className="custom-control-input" type="checkbox" name="doNotRedirectToCompletePage" id="doNotRedirectToCompletePage" value="1" onChange={this.onChange} checked={this.state.otherFields.doNotRedirectToCompletePage}/>
                          <Label className="custom-control-label" check for="doNotRedirectToCompletePage">
                            Do Not Redirect To Complete Page
                          </Label>
                          <FormText color={"muted"}>
                            If checked, it will use surveyjs complete page system
                          </FormText>
                        </div>
                      </Col>
                      <Col sm={"4"}>
                        <FormGroup key="points" >
                          <Label for="points">Total quiz points</Label>
                          <Input type={"integer"} name={"points"} onChange={this.onChange} value={this.state.otherFields.points} />
                        </FormGroup>
                        <div className="custom-control custom-checkbox mt-2" key="categoryRequired">
                          <Input className="custom-control-input" type="checkbox" name="categoryRequired" id="categoryRequired" value="1" onChange={this.onChange} checked={this.state.otherFields.categoryRequired}/>
                          <Label className="custom-control-label" check for="categoryRequired">
                            Category required
                          </Label>
                          <FormText color={"muted"}>
                            All questions must have a category (if not an error will be thrown)
                          </FormText>
                        </div>
                        <FormGroup key={"questionCategories"} className="mt-2">
                          <Label for={"questionCategories"}>Question categories</Label>
                          <CreatableSelect
                            isMulti
                            onChange={(value) => {
                              this.onChange({
                                target: {
                                  name: "questionCategories",
                                  value: value.map((el) => el.value),
                                  getAttribute: (n) => null
                                }
                              })
                            }}
                            name="questionCategories"
                            id="questionCategories"
                            value={
                              this.state.otherFields && this.state.otherFields.questionCategories
                                ? this.state.otherFields.questionCategories.map((el) => {return {label: el, value: el}})
                                : []
                            }
                          />
                        </FormGroup>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" style={{paddingTop: 20}}>
                <Row>
                  <Col sm="12">
                    <div className="row">
                      {this.getStyleFields()}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3" style={{paddingTop: 20}}>
                <Row>
                  <Col lg="4">
                    <label>Login/result logo</label>
                    <FormGroup key="loginLogo" className="forInputFile">
                      <input type="file" className="form-custom-file-input" name={"loginLogo"} id={"loginLogo"} onChange={this.handleUploadImage} />
                      <label className="custom-file-label" htmlFor={"loginLogo"}>Choose file...</label>
                    </FormGroup>
                    { this.state.loginLogoLoading ?
                      <div className="lds-dual-ring"></div>
                      :
                      this.state.loginLogo && ( 
                        <div className="previewUploadFile">
                          <div onClick={() => this.deleteUploadImage('loginLogo')} className="deleteUploadFile">x</div>
                          <img alt='logo' className="img-fluid" src={this.state.loginLogo} style={{ maxHeight: 100 }}/>
                        </div>
                      )
                    }

                    <label>Header image</label>
                    <FormGroup key="headerImage" className="forInputFile">
                      <input type="file" className="form-custom-file-input" name={"headerImage"} id={"headerImage"} onChange={this.handleUploadImage} />
                      <label className="custom-file-label" htmlFor={"headerImage"}>Choose file...</label>
                    </FormGroup>
                    { this.state.headerImageLoading ?
                      <div className="lds-dual-ring"></div>
                      :
                      this.state.headerImage && ( 
                        <div className="previewUploadFile">
                          <div onClick={() => this.deleteUploadImage('headerImage')} className="deleteUploadFile">x</div>
                          <img alt='header' className="img-fluid" src={this.state.headerImage} style={{ maxHeight: 100 }}/>
                        </div>
                      )
                    }

                    <label>Level image</label>
                    <FormGroup key="levelImage" className="forInputFile">
                      <input type="file" className="form-custom-file-input" name={"levelImage"} id={"levelImage"} onChange={this.handleUploadImage} />
                      <label className="custom-file-label" htmlFor={"levelImage"}>Choose file...</label>
                    </FormGroup>
                    { this.state.levelImageLoading ?
                      <div className="lds-dual-ring"></div>
                      :
                      this.state.levelImage && ( 
                        <div className="previewUploadFile">
                          <div onClick={() => this.deleteUploadImage('levelImage')} className="deleteUploadFile">x</div>
                          <img alt='level' className="img-fluid" src={this.state.levelImage} style={{ maxHeight: 100 }}/>
                        </div>
                      )
                    }

                    <label>Background image</label>
                    <FormGroup key="backgroundImage" className="forInputFile">
                      <input type="file" className="form-custom-file-input" name={"backgroundImage"} id={"backgroundImage"} onChange={this.handleUploadImage} />
                      <label className="custom-file-label" htmlFor={"backgroundImage"}>Choose file...</label>
                    </FormGroup>
                    { this.state.backgroundImageLoading ?
                      <div className="lds-dual-ring"></div>
                      :
                      this.state.backgroundImage && ( 
                        <div className="previewUploadFile">
                          <div onClick={() => this.deleteUploadImage('backgroundImage')} className="deleteUploadFile">x</div>
                          <img alt='background' className="img-fluid" src={this.state.backgroundImage} style={{ maxHeight: 100 }}/>
                        </div>
                      )
                    }
                    
                    <FormGroup key={"customCss"}>
                      <Label for={"customCss"}>Custom css</Label>
                      <select
                        onChange={this.onChange}
                        name="customCss"
                        id="customCss"
                        className="form-control"
                        value={
                          this.state.otherFields.customCss
                        }
                      >
                        <option value=''>No custom CSS</option>
                        {customCssFiles.map((filename, index) => <option key={index+'_'+filename}value={filename}>{filename}</option>)}
                      </select>
                      <div className="alert alert-info mt-2">Custom css files must be added in public/custom-css directory</div>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup key={"loginTitle"}>
                      <Label for={"loginTitle"}>Login title</Label>
                      <Input type={"text"} name={"loginTitle"} value={this.state.otherFields.loginTitle} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"loginText"}>
                      <Label for={"loginText"}>Login text</Label>
                      <Input type={"textarea"} name={"loginText"} value={this.state.otherFields.loginText} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"loginButton"}>
                      <Label for={"loginButton"}>Login button text</Label>
                      <Input type={"text"} name={"loginButton"} value={this.state.otherFields.loginButton} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"resultTitle"}>
                      <Label for={"resultTitle"}>Result title</Label>
                      <Input type={"text"} name={"resultTitle"} value={this.state.otherFields.resultTitle} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"resultText"}>
                      <Label for={"resultText"}>Result text</Label>
                      <Input type={"textarea"} name={"resultText"} value={this.state.otherFields.resultText} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <Row>
                      <Col sm={"6"}>
                        <FormGroup key={"resultScoreTop"}>
                          <Label for={"resultScoreTop"}>Result score top</Label>
                          <Input type={"integer"} name={"resultScoreTop"} value={this.state.otherFields.resultScoreTop} className="form-control" onChange={this.onChange} />
                        </FormGroup>
                        <FormGroup key={"resultScoreLeft"}>
                          <Label for={"resultScoreLeft"}>Result score left</Label>
                          <Input type={"integer"} name={"resultScoreLeft"} value={this.state.otherFields.resultScoreLeft} className="form-control" onChange={this.onChange} />
                        </FormGroup>
                      </Col>
                      <Col sm={"6"}>
                        <FormGroup key={"resultLevelTop"}>
                          <Label for={"resultLevelTop"}>Result level text top</Label>
                          <Input type={"integer"} name={"resultLevelTop"} value={this.state.otherFields.resultLevelTop} className="form-control" onChange={this.onChange} />
                        </FormGroup>
                        <FormGroup key={"resultLevelLeft"}>
                          <Label for={"resultLevelLeft"}>Result level text left</Label>
                          <Input type={"integer"} name={"resultLevelLeft"} value={this.state.otherFields.resultLevelLeft} className="form-control" onChange={this.onChange} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="4">
                    <div>
                      <Label for={"levels"}>Levels</Label>
                      {this.getLevelsForm()}
                      <Button
                        className={"btn btn-sm mb-4"}
                        onClick={() => {
                          this.setState({
                            otherFields: {
                              ...this.state.otherFields,
                              levels: [
                                ...(typeof this.state.otherFields.levels !== 'undefined' ? this.state.otherFields.levels : []),
                                {
                                  name: '',
                                  start: 0,
                                  end: 0
                                }
                              ]
                            }
                          })
                        }
                        }
                      >
                        Add level
                      </Button>
                    </div>
                    <div>
                      <FormGroup key={"compareToSurvey"}>
                        <Label for={"compareToSurvey"}>Compare to old survey (id)</Label>
                        <Input type={"text"} name={"compareToSurvey"} value={this.state.otherFields.compareToSurvey} className="form-control" onChange={this.onChange} />
                      </FormGroup>
                      <FormGroup key={"comparedToSurveyStable"}>
                        <Label for={"comparedToSurveyStable"}>Compared stable text</Label>
                        <Input type={"textarea"} name={"comparedToSurveyStable"} value={this.state.otherFields.comparedToSurveyStable} className="form-control" onChange={this.onChange} />
                      </FormGroup>
                      <FormGroup key={"comparedToSurveyNegative"}>
                        <Label for={"comparedToSurveyNegative"}>Compared negative text</Label>
                        <Input type={"textarea"} name={"comparedToSurveyNegative"} value={this.state.otherFields.comparedToSurveyNegative} className="form-control" onChange={this.onChange} />
                      </FormGroup>
                      <FormGroup key={"comparedToSurveyPositive"}>
                        <Label for={"comparedToSurveyPositive"}>Compared positive text</Label>
                        <Input type={"textarea"} name={"comparedToSurveyPositive"} value={this.state.otherFields.comparedToSurveyPositive} className="form-control" onChange={this.onChange} />
                      </FormGroup>
                      <FormText color={"muted"}>
                        You can use %pointsDiff% and %oldSurveyName% in comparing texts
                      </FormText>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4" style={{paddingTop: 20}}>
                <Row>
                  <Col sm="12">
                    <FormGroup key={"notAuthorizedSurvey"}>
                      <Label for={"notAuthorizedSurvey"}>Survey not allowed</Label>
                      <Input type={"text"} name={"notAuthorizedSurvey"} value={this.state.otherFields.notAuthorizedSurvey} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"surveyAlreadyDone"}>
                      <Label for={"surveyAlreadyDone"}>Survey already done</Label>
                      <Input type={"textarea"} name={"surveyAlreadyDone"} value={this.state.otherFields.surveyAlreadyDone} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"sessionError"}>
                      <Label for={"sessionError"}>Session error</Label>
                      <Input type={"textarea"} name={"sessionError"} value={this.state.otherFields.sessionError} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup key={"surveyDisabledError"}>
                      <Label for={"surveyDisabledError"}>Survey Disabled</Label>
                      <Input type={"textarea"} name={"surveyDisabledError"} value={this.state.otherFields.surveyDisabledError} className="form-control" onChange={this.onChange} />
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5" style={{paddingTop: 20}}>
                <Row>
                  <Col sm="12">
                    <label>Images</label>
                    <FormGroup key="headerImage" className="forInputFile">
                      <input type="file" className="form-custom-file-input" name={"pictures"} id={"pictures"} onChange={this.handleUploadImage} multiple />
                      <label className="custom-file-label" htmlFor={"pictures"}>Choose file...</label>
                    </FormGroup>
                    { this.state.picturesLoading ?
                      <div className="lds-dual-ring"></div>
                      :
                      this.state.pictures && ( 
                        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                        {  this.state.pictures.map((picture, index) => (
                          <>
                            <div style={{marginRight: 10}}>
                              <Button style={{padding: '2px 10px'}} onClick={() => {navigator.clipboard.writeText(`${window.location.origin}${picture}`)}}>Copier l'url</Button>

                              <div className="previewUploadFile">
                                <div onClick={() => this.deleteUploadImageByIndex('pictures', index, picture)} className="deleteUploadFile">x</div>
                                  <img alt='header' key={index} className="img-fluid" src={picture} style={{ maxHeight: 100 }}/>
                              </div>
                            </div>
                            </>
                        ))}
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Collapse>
    );
  };
  /*
    Login logo
    Login title
    Login text
    Result title
    Result text
    Result score top
    Result score left
    Result level top
    Result level left
    Levels
    Closed
    */

  onChange = (evt, i = null, fieldname = null) => {
    let name = evt.target.name;
    let value = evt.target.value;
    if (name.indexOf('levels') === 0 && i !== null && fieldname !== null) {
      name = 'levels';
      value = [
        ...this.state.otherFields.levels,
      ];
      value[i][fieldname] = evt.target.value;
    }
    const datatree = evt.target.getAttribute('data-tree');
    this.props.onChange(name, evt.target.type === "checkbox" ? !!evt.target.checked : value, datatree ? datatree : 'customFields');
  };

  getLevelsForm = () => {
    return (
      <>
        <div className={"row mb-2"}>
          <Col sm={"4"}>{"Name"}</Col>
          <Col sm={"4"}>{"Start (>=)"}</Col>
          <Col sm={"4"}>{"End (<)"}</Col>
        </div>
        {typeof this.state.otherFields.levels !== 'undefined' ? this.state.otherFields.levels.map((el, i) => {
          return (
            <div key={`level${i}`} className={"row mb-2"}>
              <Col sm={"4"}>
                <Input type={"text"} name={`levels[${i}][name]`} value={this.state.otherFields.levels[i]['name']} className="form-control" onChange={(evt) => {this.onChange(evt, i, 'name')}} placeholder={"Name"} />
              </Col>
              <Col sm={"4"}>
                <Input type={"integer"} name={`levels[${i}][start]`} value={this.state.otherFields.levels[i]['start']} className="form-control" onChange={(evt) => {this.onChange(evt, i, 'start')}} placeholder={"more or equal"} />
              </Col>
              <Col sm={"4"}>
                <Input type={"integer"} name={`levels[${i}][end]`} value={this.state.otherFields.levels[i]['end']} className="form-control" onChange={(evt) => {this.onChange(evt, i, 'end')}} placeholder={"less than"} />
              </Col>
            </div>
          );
        }): <span style={{color: 'lightgray'}}>None</span>}
      </>
    );
  }

  handleColorChange = ({hex}) => {
    this.setState({
      styleFields: {
        ...this.state.styleFields,
        [this.state.editingColor]: hex
      }
    });
    let name = this.state.editingColor;
    let value = hex;
    this.props.onChange(name, value, 'style');
  }
}
