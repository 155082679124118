import React from "react";

import {Card} from 'reactstrap';

export default (props) => {
  return (
    <div>
      <style>
        {props.customCss ? Object.keys(props.customCss).map((el) => el + '{' + props.customCss[el] + '}') : ''}
      </style>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('${props.backgroundImage ? props.backgroundImage : '/images/default-bg.jpg'}')`
        }}
        className="bg-dark d-flex justify-content-center align-items-center backgrounded-overlay"
      >
      </div>
      <div
        style={{
          position: 'relative'
        }}
        className={"backgrounded-container justify-content-center align-items-center"}
      >
        <Card
          className={"container"}
          style={{
            padding: 0,
            borderRadius: 20,
            overflow: "hidden",
            borderColor: props.style ? props.style["border-color"] : "transparent"
          }}
        >
          {props.title}
          {props.children}
        </Card>
      </div>
    </div>
  )
}
