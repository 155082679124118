import React, {Component} from "react";
import sanitizeHtml from 'sanitize-html';
import {Row, Col} from 'reactstrap';

import api from './admin/services/Api';
import Error from './components/Error';
import BackgroundedScreen from './components/BackgroundedScreen';
import qr from './admin/services/QuizRules';
import Login from './components/Login';

export default class SurveyThankYou extends Component {

  state = {
    loading: true,
    loaded: false,
    post: null,
    error: false,
    needConnection: false,
    customFields: {}
  };

  componentDidMount() {
    this.loadPostResults();
  }

  loadPostResults = async () => {
    this.setState({
      needConnection: false
    });
    let post = null;
    try {
      post = await api.getPost(this.props.match.params.postid);
    } catch (e) {
      if (e.statusCode === 403) {
        let customFields = {};
        if (e.error && e.error.customFields) {
          customFields = e.error.customFields;
        }
        this.setState({
          needConnection: true,
          customFields,
          loading: false
        });
        return null;
      }
      this.setState({
        loading: false,
        loaded: false,
        error: e.message
      });
      return null;
    }
    /* DEBUG */
    // const score = await qr.getQuizScore(post);
    /* END DEBUG */

    this.setState({
      post,
      loading: false,
      loaded: true,
      error: false
    });
  };

  renderResult = () => {
    return (
      <Row style={{marginBottom: 20}}>
        <Col md={"12"} style={{paddingTop: 50}}>
          <div>
            {this.state.post.survey.json.completedHtml}
          </div>
        </Col>
      </Row>
    );
  }

  renderResultCompare = () => {
    if (this.state.post.survey.customFields.compareToSurvey && this.state.post.oldScores && this.state.post.oldScores.length) {
      const diff = this.state.post.score.total - this.state.post.oldScores[0].total;
      const nbq = qr.getQuizNbOfQuestions(this.state.post.survey);
      const mindiff = Math.round(this.state.post.survey.customFields.points / nbq);
      return (
        <div
          style={{fontSize: '1.3em', margin: 20}}
          dangerouslySetInnerHTML={{__html:
              sanitizeHtml(
                (diff > mindiff ? this.state.post.survey.customFields.comparedToSurveyPositive :
                  (diff < 0 - mindiff ? this.state.post.survey.customFields.comparedToSurveyNegative : this.state.post.survey.customFields.comparedToSurveyStable)
                )
              )
                .replace('\n', '<br/>')
                .replace('%pointsDiff%', Math.abs(diff))
                .replace('%oldSurveyName%', this.state.post.oldScores[0].surveyName)
          }}
        ></div>
      );
    }
    return null;
  }

  render() {
    if (this.state.loading) {
      return this.state.loading;
    } else if (this.state.error) {
      return (<Error error={this.state.error} retryAction={this.loadPostResults} />);
    } else if (this.state.needConnection) {
      return (<Login customFields={this.state.customFields} onLogin={this.loadPostResults} />);
    } else if (this.state.loaded) {
      return (
        <BackgroundedScreen large backgroundImage={this.state.post.survey.customFields.backgroundImage}>

            <div className={"text-muted text-center"}>
              {this.state.post.survey.customFields.loginLogo ? <img alt="Logo" src={this.state.post.survey.customFields.loginLogo} style={{ maxWidth: '100%', marginBottom: 20 }}/> : null}
              {this.state.post.survey.customFields.resultTitle ? <h1>{this.state.post.survey.customFields.resultTitle}</h1> : null}
              {this.state.post.survey.json.completedHtml ? <div
                style={{fontSize: '1.3em', margin: 20}}
                dangerouslySetInnerHTML={{__html:
                  sanitizeHtml(this.state.post.survey.json.completedHtml.fr ? this.state.post.survey.json.completedHtml.fr : this.state.post.survey.json.completedHtml,
                    {
                      allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe', 'img', 'small']
                    }
                  ).replace('\n', '<br/>')
                }}
              ></div> : null}
            </div>
        </BackgroundedScreen>
      );
    } else if (this.state.needConnection) {
      return this.renderLogin();
    }
    return <div>Result empty</div>;
  }
}
